import { render, staticRenderFns } from "./index.vue?vue&type=template&id=9619ad86&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9619ad86",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SydV2Login: require('/opt/build/repo/components/v2/Login.vue').default})
