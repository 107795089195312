





import Vue from 'vue'
import _ from 'lodash'

export default Vue.extend({
  data(): any {
    return {
      page: {}
      // gridCols: [
      //   'md:col-start-1 md:col-span-6 lg:col-start-2 lg:col-span-5',
      //   'md:col-start-8 md:col-span-5 lg:col-start-8 lg:col-span-4',
      //   'md:col-start-5 md:col-span-8 lg:col-start-2 lg:col-span-7',
      //   'md:col-start-1 md:col-span-6 lg:col-start-7 lg:col-span-5 md:z-10',
      //   'col-start-1 col-end-13 border-1',
      // ],
    }
  },
  computed: {
    settings(): any {
      return this.$store.state.settings
    }
  },
  async fetch() {
    this.$axios
      .get(`${process.env.apiUrl}/wp-json/syd/v3/pages?path=/`)
      .then((response: any) => {
        this.page = response.data
      })
  },
  fetchOnServer: false,
  asyncData({ params, error, payload }) {
    if (payload) {
      return {
        page: payload.page
      }
    }
  },
  components: {},
  head(): Object {
    let head = Vue.filter('pageHead')(this.page, this.settings)
    return head
  },
  mounted() {},
  methods: {
    slotClasses(block: any, name: any, index: number) {
      const classes = <any>[]

      classes.push(`slot--${name} flex flex-col justify-center mt-auto mb-auto`)
      if (index !== 0) {
        let sizes = [
          {
            name: 'small',
            prefix: ''
          },
          {
            name: 'medium',
            prefix: 'md:'
          },
          {
            name: 'large',
            prefix: 'lg:'
          },
          {
            name: 'xlarge',
            prefix: 'xl:'
          }
        ]
        sizes.forEach((size: any) => {
          if (block.fields.hasOwnProperty(`placement_${size.name}`)) {
            classes.push(
              `${size.prefix}row-start-${
                block.fields[`placement_${size.name}`].row_start
              }`
            )
            classes.push(
              `${size.prefix}row-span-${
                block.fields[`placement_${size.name}`].row_span
              }`
            )
            classes.push(
              `${size.prefix}col-start-${
                block.fields[`placement_${size.name}`].col_start
              }`
            )
            classes.push(
              `${size.prefix}col-span-${
                block.fields[`placement_${size.name}`].col_span
              }`
            )
            classes.push(
              `${size.prefix}z-${
                block.fields[`placement_${size.name}`].z_index
              }`
            )
          }
        })
      } //classes.push(this.gridCols[(index - 1) % 7])

      // classes.push('col-start-1 col-end-4')
      // let row = Math.ceil(index / 2)
      // if (index !== 0) classes.push(`sm:row-start-${index} md:row-start-${row}`)

      return classes.join(' ')
    },
    getComponentName(block: any, style: string) {
      if (block && block.name) {
        if (style == 'simple' && block && block.name)
          return block.name.replace('/', '-').replace('syd-', '')
        return 'syd-blocks-' + block.name.replace('/', '-').replace('syd-', '')
      }
      return ''
    }
  }
})
